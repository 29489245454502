import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import {
  CreateDatabaseRequest,
  CreateWorkGroupRequest,
  CreateWorkSpaceRequest,
  DatabaseT,
  Pricing,
  FeatureFlags,
  UpdateWorkspaceRequest,
  WorkGroupT,
  WorkSpaceMeta,
  WorkSpaceOrgQuota,
  WorkspaceT,
  GroupMetrics,
  calculatePropsForWorkspace,
} from '@/pages/workgroup/type';

export async function getMeta(): Promise<Result<WorkSpaceMeta>> {
  const response = await axiosController.get('v2/workspaces/meta');
  return response.data;
}

export async function getPrice(): Promise<Result<Pricing>> {
  const response = await axiosController.get('v2/billing/resource-pricing');
  return response.data;
}

export async function getWorkspaceOrgQuota(): Promise<Result<WorkSpaceOrgQuota>> {
  const response = await axiosController.get('v2/org/quota');
  return response.data;
}

export async function getFeatureFlag(): Promise<
  Result<
    {
      feature_flag: FeatureFlags;
      org_id: string;
    }[]
  >
> {
  const response = await axiosController.get('v2/org/self');
  return response.data;
}

export async function createWorkGroup(data: CreateWorkGroupRequest): Promise<Result<WorkGroupT>> {
  const response = await axiosController.post('v2/workgroups', data);
  return response.data;
}

export async function getWorkGroups(): Promise<Result<WorkGroupT[]>> {
  const response = await axiosController.get('v2/workgroups');
  return response.data;
}

export async function getWorkGroupDetail(group_id: string): Promise<Result<WorkGroupT>> {
  const response = await axiosController.get<Result<WorkGroupT>>(`v2/workgroups/${group_id}`);
  if (response.data?.Result) {
    const group = response.data?.Result;
    group.workspaces = group.workspaces.map((ws: WorkspaceT) => calculatePropsForWorkspace(ws, group));
    response.data.Result = group;
  }
  return response.data;
}

export async function updateWorkGroup(
  group_id: string,
  data: { workgroup_name: string; enable_application_log?: boolean; encryption_method?: string }
): Promise<Result<void>> {
  const response = await axiosController.put(`v2/workgroups/${group_id}`, data);
  return response.data;
}

export async function deleteWorkGroup(group_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}`);
  return response.data;
}

export async function createWorkSpace(data: CreateWorkSpaceRequest): Promise<Result<WorkspaceT>> {
  const response = await axiosController.post(`v2/workgroups/${data.workgroup_id}/workspaces`, data);
  return response.data;
}

export async function getWorkSpaceDetail(group_id: string, space_id: string): Promise<Result<WorkspaceT>> {
  const response = await axiosController.get(`v2/workgroups/${group_id}/workspaces/${space_id}`);
  return response.data;
}

export async function deleteWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}/workspaces/${space_id}`);
  return response.data;
}

export async function pauseWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/pause`);
  return response.data;
}

export async function resumeWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/resume`);
  return response.data;
}

export async function refreshWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/refresh`);
  return response.data;
}

export async function updateWorkSpace(
  group_id: string,
  space_id: string,
  data: UpdateWorkspaceRequest,
  isAdminDashboardPage?: boolean,
  org_id?: string
): Promise<Result<WorkspaceT>> {
  const response = await axiosController.put(
    `${isAdminDashboardPage ? `admin/orgs/${org_id!}` : 'v2'}/workgroups/${group_id}/workspaces/${space_id}`,
    data
  );
  return response.data;
}

export async function createDatabase(data: CreateDatabaseRequest): Promise<Result<DatabaseT>> {
  const response = await axiosController.post(`v2/workgroups/${data.workgroup_id}/databases`, data);
  return response.data;
}

export async function renameDatabase(group_id: string, db_id: string, database_name: string): Promise<Result<void>> {
  const response = await axiosController.put(`v2/workgroups/${group_id}/databases/${db_id}`, {
    tg_database_name: database_name,
  });
  return response.data;
}

export async function deleteDatabase(group_id: string, db_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}/databases/${db_id}`);
  return response.data;
}

export async function getGroupMetrics(group_id: string): Promise<Result<GroupMetrics>> {
  const response = await axiosController.get(`v2/observability/${group_id}/metrics`);
  return response.data;
}
